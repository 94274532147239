import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import modalReducer from "./slices/modal";
import classReducer from "./slices/class";
import driverReducer from "./slices/driver";
import loaderReducer from "./slices/loader";
import bookingReducer from "./slices/booking";
import vehicleReducer from "./slices/vehicle";
import zoneReducer from "./slices/zone";

const appReducer = combineReducers({
	form: formReducer,

	auth: authReducer,
	class: classReducer,
	modal: modalReducer,
	driver: driverReducer,
	loader: loaderReducer,
	vehicle: vehicleReducer,
	booking: bookingReducer,
	zone:zoneReducer,
});

const rootReducer = (state: any, action: any) => {
	if (action.type === "auth/logout") state = {};
	return appReducer(state, action);
};

export default rootReducer;
